import { VStack, Flex, type FlexProps, Text, Page as BasePage } from '@strike-apps/shared/ui'
import { StrikeWordmarkHeader } from './StrikeWordmarkHeader'

interface PageProps {
  header?: string
  body?: string | string[]
  includeLogout?: boolean
  children?: React.ReactNode
  childrenContainerProps?: FlexProps
}

export function SimplePage({
  header,
  body,
  includeLogout = true,
  children,
  childrenContainerProps,
}: PageProps) {
  const normalizedBody = typeof body === 'string' ? [body] : body

  return (
    <BasePage header={<StrikeWordmarkHeader includeLogout={includeLogout} />}>
      <Flex
        flexDir="column"
        gap={12}
        borderWidth="1px"
        borderColor="borderPrimary"
        borderRadius={12}
        p={16}
        w="471px"
        {...childrenContainerProps}
      >
        {(header || normalizedBody) && (
          <VStack textAlign="center" spacing={4}>
            {header && (
              <Text variant="title2" color="facePrimary">
                {header}
              </Text>
            )}
            {(normalizedBody ?? []).map((text, index) => (
              <Text key={index} variant="body3" color="faceSecondary">
                {text}
              </Text>
            ))}
          </VStack>
        )}
        {children}
      </Flex>
    </BasePage>
  )
}

import type { TextElement, TextElementValue } from '@ln-zap/consumer-api'
import type { Currency } from '@strike-apps/strike/api-client'
import BigNumber from 'bignumber.js'
import { useFormatCurrency } from './useFormatCurrency'
import format from 'date-fns/format'

export const useFormatTextElement = () => {
  const formatCurrency = useFormatCurrency()

  return (textElement: TextElement) => {
    const tokens = textElement.text.split(/({\w+}|\n)/g).filter((token) => token !== '')
    const formatValue = (value: TextElementValue) => {
      if (value.amount && value.currency) {
        return formatCurrency(value.amount, value.currency as Currency).displayAmount
      }

      if (value.text) {
        return value.text
      }

      if (value.dateTime) {
        return new Date(value.dateTime).toLocaleString()
      }

      if (
        value.formattedDate &&
        value.formattedDate.timestamp &&
        value.formattedDate.formatPattern
      ) {
        return format(
          new Date(value.formattedDate.timestamp),
          value.formattedDate.formatPattern.replace(/\btt\b/g, 'a'),
        )
      }

      if (value.percent) {
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(BigNumber(value.percent).dividedBy(100).toNumber())
      }

      if (value.url) {
        return value.url.text
      }

      return ''
    }

    return tokens
      .map((token) => {
        if (token.startsWith('{') && token.endsWith('}')) {
          const key = token.slice(1, -1)

          const value = textElement.values[key]

          if (!value) {
            return null
          }

          return formatValue(value)
        }

        return token
      })
      .join('')
  }
}

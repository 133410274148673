import { useQuery } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'

import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { Balance } from '@strike-apps/strike/api-client'

import { GET_BALANCE_QUERY } from './queries'

export const useBalanceQuery = () => {
  return useQuery<Balance[], StrikeFetchError<ErrorDetails>>([GET_BALANCE_QUERY], () =>
    fetch<Balance[]>('/api/balance', { method: 'GET' }).then((data) => {
      // TODO: remove this if we stop getting bugsnag notifications about balance response not being an array
      if (!Array.isArray(data)) {
        const error = new Error(`balance unexpected response: ${JSON.stringify(data)}`)
        Bugsnag.notify(error)
        throw error
      }

      return data
    }),
  )
}

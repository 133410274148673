import { Box, Flex, Icon } from '@chakra-ui/react'
import { Text, type TextProps } from '../../typography/Text'
import { CopyIcon } from '@strike-apps/shared/icons'

/**
 * split - Splits `length` into number of `chunks`. If `maxEdgeChunkLength` is specified then first and last
 * elements won't exceed it.
 */
function split(length: number, chunks: number, maxEdgeChunkLength = 0): number[] {
  const regularPartition = Math.floor(length / chunks)
  const edgeDelta = maxEdgeChunkLength && Math.max(regularPartition - maxEdgeChunkLength, 0)
  const remainder = length - regularPartition * chunks + 2 * edgeDelta
  const result = Array(chunks).fill(regularPartition)
  result[0] -= edgeDelta
  result[chunks - 1] -= edgeDelta

  // Distribute the remainder evenly across the middle parts
  if (maxEdgeChunkLength) {
    split(remainder, chunks - 2, undefined).forEach((v, i) => {
      result[i + 1] += v
    })
  } else {
    // Distribute the remainder evenly starting from the first element
    Array(remainder)
      .fill(0)
      .forEach((v, i) => {
        result[i] += 1
      })
  }

  return result
}

export const splitString = (
  input: string,
  chunkCount: number,
  maxEdgeChunkLength: number,
): string[] => {
  const chunks = split(input.length, chunkCount, maxEdgeChunkLength)
  const sum = (acc: number, next: number) => acc + next
  return chunks.map((value, index) => {
    return input.substr(chunks.slice(0, index).reduce(sum, 0), value)
  })
}

const getNextRowWidth = ({
  charWidth,
  maxWidth,
  chunks,
  index,
}: {
  charWidth: number
  maxWidth: number
  chunks: string[]
  index: number
}) => {
  // Reduce array slice until total row width exceeds given
  return chunks.slice(index).reduce(
    (acc, next, idx) => {
      if (!acc.done && acc.width + next.length * charWidth < maxWidth) {
        acc.width += next.length * charWidth
        acc.idx = index + idx
      } else {
        // Don't search further. This is especially important to ensure all row elements
        // come in sequence (otherwise we could find shorter element somewhere
        // after the gap which can't be part of a row)
        acc.done = true
      }
      return acc
    },
    { width: 0, idx: index, done: false },
  )
}

/**
 * getContainerWidth - Calculates precise container width given `maxWidth` and `chunks` array.
 */
const getContainerWidth = ({
  charWidth,
  maxWidth,
  chunks,
}: {
  charWidth: number
  maxWidth: number
  chunks: string[]
}): number => {
  let index = 0
  let result = 0
  while (index < chunks.length) {
    const { width, idx } = getNextRowWidth({ charWidth, maxWidth, chunks, index })
    result = Math.max(width, result)
    index = idx + 1
  }
  return result
}

export interface BitcoinAddressProps extends TextProps {
  address: string
  maxWidth?: number
  onCopy?: () => void
}

const BitcoinAddress = ({ address, onCopy, maxWidth = 340, ...rest }: BitcoinAddressProps) => {
  const chunks = splitString(address, address.length <= 42 ? 4 : 6, 6).map((c, index, array) => {
    // Add some padding with spaces
    if (index === 0) {
      return ` ${c}`
    }
    if (index === array.length - 1) {
      return `${c} `
    }
    return ` ${c} `
  })
  // Sanity check. Incase of a bug in splitting the address, fallback to the original address
  const shouldUseFallback = chunks.join('').replace(/ /g, '') !== address
  // mono1 text variant single char width
  const charWidth = 8.5

  return (
    <Flex align="center" {...rest}>
      {onCopy && (
        <Icon
          role="button"
          aria-label="Copy bitcoin address to clipboard"
          color="facePrimary"
          as={CopyIcon}
          mr={'2px'}
          position="relative"
          onClick={onCopy}
          top="2px"
          sx={{
            cursor: 'pointer',
          }}
        />
      )}
      {shouldUseFallback && (
        <Text maxWidth={maxWidth} variant="mono1">
          {address}
        </Text>
      )}
      {!shouldUseFallback && (
        <Box
          overflow="hidden"
          width={getContainerWidth({ charWidth, maxWidth, chunks }) - 2 * charWidth}
        >
          <Flex
            flexWrap="wrap"
            position="relative"
            left={-charWidth}
            py={2}
            width={getContainerWidth({ charWidth, maxWidth, chunks })}
          >
            {chunks.map((value, index) => (
              <Box
                color={index === 0 || index === chunks.length - 1 ? 'facePrimary' : 'faceTertiary'}
                fontWeight="medium"
                key={index}
                sx={{ display: 'inline-block' }}
              >
                <Text variant="mono1" sx={{ whiteSpace: 'pre' }}>
                  {value}
                </Text>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  )
}

export { BitcoinAddress }

import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export function useSessionId() {
  const [sessionId, setSessionId] = useState<string | null>(null)

  useEffect(() => {
    let storedSession = sessionStorage.getItem('sessionId')
    if (!storedSession) {
      storedSession = uuidv4()
      sessionStorage.setItem('sessionId', storedSession)
    }
    setSessionId(storedSession)
  }, [])

  return sessionId
}

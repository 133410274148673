import { useToast } from './useToast'

export const useCopy = (copyText: string, onCopyMessage: string, onCopyHeader = 'Copied') => {
  const { showToast } = useToast(copyText)

  return async () => {
    try {
      await navigator.clipboard.writeText(copyText)
      showToast({
        toastType: 'success',
        title: onCopyHeader,
        message: onCopyMessage,
      })
    } catch (error) {
      showToast({
        toastType: 'error',
        title: 'Error',
        message: "Couldn't copy text to clipboard",
      })
    }
  }
}

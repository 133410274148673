import Script from 'next/script'
import { useEffect } from 'react'

declare global {
  interface Window {
    _sift: Array<unknown>
  }
}

export interface SiftScriptProps {
  beaconKey: string
  userId: string
  sessionId: string | null
}

export const SiftScript = ({ beaconKey, userId, sessionId }: SiftScriptProps) => {
  useEffect(() => {
    const _sift = (window._sift = window._sift || [])
    _sift.push(['_setAccount', beaconKey])
    _sift.push(['_setUserId', userId])
    _sift.push(['_setSessionId', sessionId])
    _sift.push(['_trackPageview'])
  }, [beaconKey, sessionId, userId])

  return <Script strategy="afterInteractive" src="https://cdn.sift.com/s.js" />
}

import { createContext, useContext, type ReactNode, useEffect, useState } from 'react'
import Avo, { AvoEnv } from './Avo'
import Bugsnag from '@bugsnag/js'

const AvoContext = createContext<typeof Avo | null>(null)

interface AvoProviderProps {
  isAllowed: boolean
  userId?: string
  isSandboxEnvironment: boolean
  rudderstackWriteKey: string
  rudderstackDataPlaneUrl: string
  children: ReactNode
}

export function AvoProvider({
  isAllowed,
  userId,
  isSandboxEnvironment,
  rudderstackWriteKey,
  rudderstackDataPlaneUrl,
  children,
}: AvoProviderProps) {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      if (!isAllowed || isInitialized || !userId) {
        return
      }

      const { RudderAnalytics } = await import('@rudderstack/analytics-js')
      const analytics = new RudderAnalytics()

      analytics.load(rudderstackWriteKey, rudderstackDataPlaneUrl)

      Avo.initAvo(
        {
          env: isSandboxEnvironment ? AvoEnv.Dev : AvoEnv.Prod,
          webDebugger: false,
        },
        null,
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          logEvent: (eventName: string, eventProperties: Record<string, any>) => {
            analytics.track(eventName, eventProperties)
          },
          identify: (userId: string) => {
            analytics.identify(userId)
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          logPage: (pageName: string, pageProperties: Record<string, any>) => {
            analytics.page(pageName, pageProperties)
          },
        },
      )
      Avo.identify({ userId_: userId })

      setIsInitialized(true)
    }

    initialize().catch(Bugsnag.notify)
  }, [
    isSandboxEnvironment,
    rudderstackWriteKey,
    rudderstackDataPlaneUrl,
    isInitialized,
    userId,
    isAllowed,
  ])

  return <AvoContext.Provider value={isInitialized ? Avo : null}>{children}</AvoContext.Provider>
}

export function useAvo() {
  return useContext(AvoContext)
}

import type { ReactNode } from 'react'
import { Flex, type FlexProps } from '../layout/Flex'
import { Text } from '../typography/Text'
import { Avatar } from '../media-and-icons/Avatar'

export interface UserNameProps extends FlexProps {
  userHandle: string
  avatar?: string
  children?: ReactNode
}

export function UserName({ userHandle, avatar, children, ...rest }: UserNameProps) {
  return (
    <Flex alignItems="center" {...rest}>
      <Avatar
        bg="layerBackground"
        color="faceAccent"
        alignItems="center"
        size="2xs"
        name={userHandle}
        initialOnly
        src={avatar}
      />
      <Text variant="subheadline2" color="facePrimary" ml={1}>
        {children ?? userHandle}
      </Text>
    </Flex>
  )
}

import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const avatarStyles: ComponentMultiStyleConfig = {
  parts: ['container', 'label'],

  baseStyle: {
    container: {
      borderRadius: '50%',
    },
    label: {
      fontFamily: 'mono',
    },
  },
}

export { default as UsaFlagIcon } from './usa.svg'
export { default as CanadaFlagIcon } from './canada.svg'
export { default as MexicoFlagIcon } from './mexico.svg'
export { default as ElSalvadorFlagIcon } from './el-salvador.svg'
export { default as ArgentinaFlagIcon } from './argentina.svg'
export { default as EuropeFlagIcon } from './eu.svg'
export { default as BeninFlagIcon } from './benin.svg'
export { default as GhanaFlagIcon } from './ghana.svg'
export { default as KenyaFlagIcon } from './kenya.svg'
export { default as SenegalFlagIcon } from './senegal.svg'
export { default as NigeriaFlagIcon } from './nigeria.svg'
export { default as PhilippinesFlagIcon } from './philippines.svg'
export { default as UkFlagIcon } from './gb.svg'

import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { UserInfoResp } from '@ln-zap/consumer-api'
import { USER_INFO_QUERY } from './queries'
import Bugsnag from '@bugsnag/js'

export const useUserInfo = (
  options?: UseQueryOptions<UserInfoResp, StrikeFetchError<ErrorDetails>>,
) => {
  return useQuery<UserInfoResp, StrikeFetchError<ErrorDetails>>(
    [USER_INFO_QUERY],
    () =>
      fetch<UserInfoResp>('/api/user/info', { method: 'GET' }).then((data) => {
        // TODO: remove this if we stop getting bugsnag notifications about featureOptions being undefined
        if (!data?.featureOptions) {
          // just sending the keys of the object to bugsnag since user info values contain PII
          const error = new Error(
            `user info object keys in unexpected response: ${Object.getOwnPropertyNames(data)}`,
          )
          Bugsnag.notify(error)
          throw error
        }

        return data
      }),
    {
      staleTime: 5000,
      ...options,
    },
  )
}

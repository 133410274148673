import { noop } from 'lodash-es'
import type { CookieConsentConfig } from 'vanilla-cookieconsent'

const getConfig = () => {
  const config: CookieConsentConfig = {
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
      useLocalStorage: true,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: 'box wide',
        position: 'bottom center',
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'bar wide',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },

    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
        services: {
          bugsnag: {
            label: 'Bugsnag error tracking',
            onAccept: noop,
            onReject: noop,
          },
          sift: {
            label: 'Sift',
            onAccept: noop,
            onReject: noop,
          },
        },
      },
      optional: {
        autoClear: {
          cookies: [
            {
              name: 'country',
            },
            {
              name: 'strike-chakra-ui-color-mode',
            },
          ],
        },
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: '_gid', // string: exact cookie name
            },
          ],
        },

        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          analytics: {
            label: 'Analytics',
            onAccept: noop,
            onReject: noop,
          },
        },
      },
    },

    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'Strike uses cookies to improve your experience.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences',
          },
          preferencesModal: {
            title: 'Manage cookie preferences',

            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Your Privacy Choices',
                description: `You can manage your preferences using the toggles below.  For more information, see our  <a href="/legal/privacy" target="_blank">Privacy</a> and <a href="/legal/cookie-policy" target="_blank">Cookie Policy</a>.`,
              },
              {
                title: 'Strictly Necessary',
                description:
                  'These cookies are necessary for our website to function and provide the services you request, and cannot be switched off.  They can include, for example, collecting information about website bugs and logging in or saving important user inputs like your cookie preferences.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary',
              },
              {
                title: 'Performance',
                description:
                  'These cookies allow us to analyse the usage and traffic on our website in order to measure and enhance the experience on our website.  They can include counting the number of visitors to the website or webpage and how visitors navigate through it.   If disabled, we won’t know when you visited the website, cannot monitor its performance, and will not remember certain optional preferences you select.',
                linkedCategory: 'analytics',
              },
            ],
          },
        },
      },
    },
  }

  return config
}

export default getConfig

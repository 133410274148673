import { EnvSettingsProvider } from '@strike-apps/commerce-dashboard/env-settings'
import {
  LocalErrorBoundary,
  UserSettingsProvider,
} from '@strike-apps/commerce-dashboard/feature-layout'
import {
  GoogleAnalyticsScripts,
  SiftScript,
  useAnalyticsPageView,
  useSessionId,
} from '@strike-apps/shared/analytics'
import { getGlobalErrorBoundary } from '@strike-apps/shared/bugsnag'
import { FeatureFlagsProvider } from '@strike-apps/shared/feature-flags/react'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { NextComponentType } from 'next'
import { SessionProvider as NextAuthSessionProvider } from 'next-auth/react'

import App, { type AppContext, type AppInitialProps, type AppProps } from 'next/app'
import { type FC, type ReactNode, useState } from 'react'
import { PUBLIC_CONFIG } from '../lib/config/public-config'
import { StrikeDashboardUiProvider } from '../lib/providers/StrikeDashboardUiProvider'

import { useCookieConsent } from '@strike-apps/commerce-dashboard/gdpr'
import { SEO } from '@strike-apps/commerce-dashboard/ui'
import { getIdpLogoutUrl } from '@strike-apps/commerce-dashboard/util-auth/logout-url'
import { getRoadblockRedirectDestinationFromError } from '@strike-apps/commerce-dashboard/utils'
import { useToast } from '@strike-apps/shared/ui'
import { getCountryFromReq } from '@strike-apps/shared/utils'
import { isStrikeFetchError } from '@strike-apps/strike/fetch'
import { type Session } from 'next-auth/core/types'
import { useRouter } from 'next/router'
import { AvoProvider } from '@strike-apps/commerce-dashboard/avo'

function isAnonymousComponent(
  component: NextComponentType,
): component is NextComponentType & { anonymous: true } {
  return (component as NextComponentType & { anonymous?: boolean }).anonymous === true
}

interface UserSessionProviderProps {
  children: ReactNode
  isAnon?: boolean
  session?: Session
}

/* After we introduced the federated logout, the click on the "Logout" button within the dashboard would cause the
 * user to be redirected to IDP to log out of it first, and then be redirected to `logout.tsx` page to be logged out
 * of dashboard as well.
 * The `NextAuthSessionProvider` call `getSession` and the `logout.tsx` component calls the `signOut`, and sometimes
 * the issue would occur where the `getSession` call would end AFTER the `signOut` call is finished.
 * That is a problem because the `getSession` call sets session cookies while `signOut` was supposed to delete them.
 * In effect that would cause the user to remain logged in.
 * To mitigate that the `anonymous` components are introduced for which we don't add `NextAuthSessionProvider` to
 * avoid the `getSession` call altogether. The `logout.tsx` component is such a component.
 */
const UserSessionProvider: FC<UserSessionProviderProps> = ({ session, isAnon, children }) => {
  return (
    <>
      {isAnon ? (
        children
      ) : (
        <NextAuthSessionProvider session={session}>
          <UserSettingsProvider>{children}</UserSettingsProvider>
        </NextAuthSessionProvider>
      )}
    </>
  )
}

interface CustomAppProps extends Omit<AppProps, 'Component'> {
  country: string
  Component: AppProps['Component'] & {
    PageLayout: React.FC
    pageLayoutProps: Record<string, unknown>
  }
}
const GlobalErrorBoundary = getGlobalErrorBoundary({
  ...PUBLIC_CONFIG,
})

function CustomApp({ Component, pageProps, country }: CustomAppProps) {
  const router = useRouter()
  const consent = useCookieConsent(country)

  useAnalyticsPageView(PUBLIC_CONFIG.gaMeasurementId)
  const sessionId = useSessionId()
  const toast = useToast()

  const handleError = (error: unknown) => {
    const roadblockRedirectDestination = getRoadblockRedirectDestinationFromError(error)

    if (roadblockRedirectDestination && router.pathname !== roadblockRedirectDestination) {
      return router.replace(roadblockRedirectDestination)
    }

    if (isStrikeFetchError(error) && error.response.status === 401) {
      console.log('Unauthorized error, logging out...')
      toast.showToast({
        toastType: 'warning',
        message:
          'The session has expired. You need to log in again to continue using the application. Logging out...',
      })
      const signOutUrl = getIdpLogoutUrl(pageProps.session, PUBLIC_CONFIG)
      window.location.href = signOutUrl
    }
  }
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,

            // override retry options for 'test' environment to get better test performance
            retry: PUBLIC_CONFIG.rqRetryCount,
            onError: handleError,
            retryDelay: (attemptIndex) =>
              Math.min(PUBLIC_CONFIG.rqRetryIncrementalDelay * 2 ** attemptIndex, 30000),
          },
          mutations: {
            onError: handleError,
          },
        },
      }),
  )

  return (
    <GlobalErrorBoundary>
      <FeatureFlagsProvider
        clientSideId={PUBLIC_CONFIG.featureFlagsClientKey}
        options={{
          bootstrap: pageProps.featureFlags,
        }}
        session={pageProps.session}
      >
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <EnvSettingsProvider envSettings={PUBLIC_CONFIG}>
              <StrikeDashboardUiProvider>
                <UserSessionProvider
                  session={pageProps.session}
                  isAnon={isAnonymousComponent(Component)}
                >
                  <SEO />
                  {PUBLIC_CONFIG.environment === 'live' && (
                    <GoogleAnalyticsScripts
                      measurementId={PUBLIC_CONFIG.gaMeasurementId}
                      cookiesAllowed={consent.isAnalyticsAllowed}
                    />
                  )}

                  {PUBLIC_CONFIG.environment === 'live' && (
                    <SiftScript
                      beaconKey={PUBLIC_CONFIG.siftBeaconKey}
                      sessionId={sessionId}
                      userId={pageProps.session?.user.id}
                    />
                  )}

                  <LocalErrorBoundary>
                    <AvoProvider
                      isAllowed={!PUBLIC_CONFIG.isTestEnvironment && consent.isAnalyticsAllowed}
                      userId={pageProps.session?.user.id}
                      isSandboxEnvironment={PUBLIC_CONFIG.isSandboxEnvironment}
                      rudderstackDataPlaneUrl={PUBLIC_CONFIG.rudderstackDataPlaneUrl}
                      rudderstackWriteKey={PUBLIC_CONFIG.rudderstackWriteKey}
                    >
                      {Component.PageLayout ? (
                        <Component.PageLayout {...pageProps} {...Component.pageLayoutProps}>
                          <Component ipCountry={country} {...pageProps} />
                        </Component.PageLayout>
                      ) : (
                        <Component ipCountry={country} {...pageProps} />
                      )}
                    </AvoProvider>
                  </LocalErrorBoundary>
                </UserSessionProvider>
              </StrikeDashboardUiProvider>
            </EnvSettingsProvider>
          </Hydrate>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </GlobalErrorBoundary>
  )
}

CustomApp.getInitialProps = async (
  context: AppContext,
): Promise<
  AppInitialProps & {
    country: string
  }
> => {
  const ctx = await App.getInitialProps(context)
  const country = getCountryFromReq(context.ctx.req)
  return { ...ctx, country }
}

export default CustomApp
